import configuration from "./config";
import { logger } from "./logger";

class Bits {
    env = "prod";
    config = null;
    moduleSetList = {};

    constructor() {
        // Run optional init function
        if (window.tv2bits?.init && typeof window.tv2bits.init === "function") {
            const options = window.tv2bits.init();
            options.env ? (this.env = options.env) : null;
        }

        this.config = configuration[this.env];
        logger.info(`Initializing (env: ${this.env})`);
    }

    /**
     * Add module set to load
     */
    async add({ key, version = "latest", instance }) {
        let promises = [];

        // Module set
        const existing = this.moduleSetList[key];
        if (!existing) {
            this.moduleSetList[key] = {
                key,
                version
            };
            const moduleSetPromise = this.loadModuleSet(key);
            this.moduleSetList[key].promise = moduleSetPromise;
            promises.push(moduleSetPromise);
        } else if (existing.version !== version) {
            throw new Error(
                `You are trying to use two different versions of the same module set: ${key} (${existing.version} !== ${version}). Only loading \"${existing.version}\".`
            );
        } else {
            promises.push(existing.promise);
        }

        // Module instance
        if (instance) {
            promises.push(this.loadModuleInstance(instance));
        }

        return Promise.all(promises);
    }

    /**
     * Load module set
     */
    async loadModuleSet(key) {
        if (!this.config) {
            return logger.error("Missing configuration.");
        }

        const entry = this.moduleSetList[key];

        // Load module set
        const response = await fetch(
            `${this.config.apiUrl}/public/module_sets/${key}?version=${entry.version}&referer=${window.location.href}`
        );
        const moduleSet = await response.json();
        if (!response.ok) {
            throw new Error(moduleSet?.description || "Failed to load module set.");
        }

        // Iterate resources
        const moduleSetVersion = moduleSet?.version || moduleSet?.lastVersion;

        moduleSetVersion?.resources
            .filter((r) => r.path?.toLowerCase().endsWith(".js"))
            .forEach((resource) => {
                const version = moduleSetVersion.version;
                let script = document.createElement("script");
                script.type = "module";
                script.setAttribute("async", "");
                script.setAttribute(
                    "src",
                    `${this.config.cdnUrl}/${moduleSet.key}/${version}/${resource.path}`
                );
                script.onload = () => {
                    this.moduleSetList[key].loaded = true;
                    logger.info(`${moduleSet.key}:${version} -> LOADED`);
                };
                script.onerror = () => {
                    this.moduleSetList[key].loaded = false;
                    logger.info(`${key}:${version} -> FAILED`);
                };
                document.body.appendChild(script);
            });

        return moduleSet;
    }

    /**
     * Unload module set
     * (TODO: Not used until we add tracking of modules in the DOM)
     */
    unloadModuleSet() {
        const scripts = Array.from(document.getElementsByTagName("script"));
        scripts.forEach((elm) => {
            if (elm.getAttribute("src").startsWith(this.config.cdnUrl)) {
                elm.parentNode.removeChild(elm);
            }
        });
    }

    async loadModuleInstance(instanceId) {
        const response = await fetch(
            `${this.config.apiUrl}/public/module_instances/${instanceId}?referer=${window.location.href}`
        );
        const data = await response.json();
        if (!response.ok) {
            throw new Error(data?.description || "Failed to load module instance.");
        }
        return data;
    }
}

var global = window || global;
global.tv2bits = new Bits();
