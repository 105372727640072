import { logger } from "../logger";
import { randomString } from "../utils";
import { styles } from "./styles";
import { editableWrapper } from "./templates";

export class BitsTag extends HTMLElement {
    uid = randomString(12);
    node;
    fields;
    moduleSetKey;
    moduleEntryKey;
    instance;
    version;
    editable;
    loaded;
    refreshTimeout;
    element;
    settingsWindow;

    constructor() {
        super();
        this.node = this.attachShadow({ mode: "open" });
    }

    connectedCallback() {
        this.init();

        // Watch for changes to attributes
        this.observeAttrChange(this, () => {
            clearTimeout(this.refreshTimeout);
            this.refreshTimeout = setTimeout(() => {
                this.init();
            }, 100);
        });
    }

    observeAttrChange(el, callback) {
        var observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === "attributes") {
                    let newVal = mutation.target.getAttribute(mutation.attributeName);
                    callback(mutation.attributeName, newVal);
                }
            });
        });
        observer.observe(el, { attributes: true });
        return observer;
    }

    init() {
        this.version = this.attributes?.version?.value || "latest";
        this.instance = this.attributes?.instance?.value || null;
        this.editable = this.attributes?.editable && this.attributes?.editable?.value !== "false";

        let exclude = ["version", "editable", "instance", "id", "class", "style"];
        this.fields = {};
        for (const attribute of this.attributes) {
            if (attribute.name === "use") {
                const keys = attribute.value.split("/");
                this.moduleSetKey = keys?.[0];
                this.moduleEntryKey = keys?.[1];
            } else if (!exclude.includes(attribute.name)) {
                this.fields[attribute.name] = attribute.value || true;
            }
        }

        this.loadModule();
    }

    loadModule() {
        if (!this.moduleSetKey || !this.moduleEntryKey) {
            return logger.warn(
                'Unable to load component. Value of "use" attribute is not in the format of "[MODULE_SET_KEY]/[MODULE_ENTRY_KEY]".'
            );
        }

        if (!window["tv2bits"]) {
            return logger.warn("Bits library not loaded.");
        }

        window["tv2bits"]
            .add({ key: this.moduleSetKey, version: this.version, instance: this.instance })
            .then((response) => {
                const moduleSetVersionNumber = response[0]?.version?.version;
                let error = null;

                // Check if module instance data is loaded
                if (response?.length > 1) {
                    this.fields = response[1].values;

                    // Check if matching versions
                    if (moduleSetVersionNumber !== response[1].version) {
                        error = `Module set version ${moduleSetVersionNumber} does not match instance version ${response[1].version}`;
                    }
                }

                this.loaded = true;
                this.renderModule(error);
            })
            .catch((err) => {
                logger.error(err.message);
                this.renderModule(err.message);
            });
    }

    renderModule(error) {
        this.node.innerHTML = "";
        let rootElement = this.node;

        // Editable
        if (this.editable) {
            this.renderEditable(error);
            rootElement = this.node.querySelector(".content");
        }

        // Create module element
        const element = document.createElement(this.moduleEntryKey);
        for (const [key, value] of Object.entries(this.fields)) {
            if (value) {
                element.setAttribute(key, value);
            }
        }

        rootElement.appendChild(element);
    }

    renderEditable(error) {
        // Add styles
        const sheet = new CSSStyleSheet();
        sheet.replaceSync(styles);
        this.node.adoptedStyleSheets = [sheet];

        // Add editable controls
        const wrapper = editableWrapper.content.cloneNode(true);
        this.node.replaceChildren(wrapper);

        // Error message
        if (error) {
            const errorButton = this.node.querySelector(".error-btn");
            errorButton.style.display = "flex";
            errorButton.addEventListener("click", () => {
                alert(error);
            });
        }
        this.node
            .querySelector(".settings-btn")
            .addEventListener("click", this.openSettings.bind(this));
    }

    openSettings() {
        const config = window["tv2bits"]?.config;
        const width = 500;
        const height = 700;
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
        const left = width / 2 + dualScreenLeft;
        const top = height / 2 + dualScreenTop;

        // Event listener
        if (!this.settingsWindow) {
            window.addEventListener("message", (event) => {
                const data = event?.data?.tv2bits;
                if (data && data.uid === this.uid) {
                    switch (data.action) {
                        case "SETTINGS_READY":
                            this.syncSettingsWindow();
                            break;
                        case "SETTINGS_CHANGED":
                            this.broadcastChanges(data);
                            break;
                    }
                }
            });
            // Close window when parent window is closes
            window.addEventListener("beforeunload", () => {
                this.settingsWindow.close();
            });
        }

        // Open window
        if (!this.settingsWindow || this.settingsWindow.closed) {
            this.settingsWindow = window.open(
                `${config.webUrl}/settings/${this.moduleSetKey}/${this.moduleEntryKey}/${this.version}?uid=${this.uid}`,
                "_blank",
                `width=${width},height=${height},top=${top},left=${left}`
            );
        } else {
            this.settingsWindow.focus();
        }
    }

    syncSettingsWindow() {
        this.settingsWindow?.postMessage(
            {
                tv2bits: {
                    uid: this.uid,
                    action: "SETTINGS_UPDATE",
                    instanceId: this.instance,
                    fields: this.fields
                }
            },
            "*"
        );
    }

    broadcastChanges(data) {
        this.node.dispatchEvent(
            new CustomEvent("change", {
                bubbles: true,
                composed: true,
                detail: data
            })
        );
    }
}

if (!window.customElements.get("tv2-bits")) {
    window.customElements.define("tv2-bits", BitsTag);
}
