const LOG_PREFIX = "[TV 2 Bits]";

export const logger = {
    info: (message) => {
        console.log(LOG_PREFIX, message);
    },
    warn: (message) => {
        console.warn(LOG_PREFIX, message);
    },
    error: (message) => {
        console.error(LOG_PREFIX, message);
    }
};
