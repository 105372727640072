const editableWrapper = document.createElement("template");
editableWrapper.innerHTML = `
<div class="editable">
    <div class="controls">
        <button class="settings-btn">
            <div class="gear-icon" />
        </button>
        <button class="error-btn" style="display:none;">
            <div class="error-icon" />
        </button>
    </div>
    <div class="content"></div>
</div>
`;

export { editableWrapper };
