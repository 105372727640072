const config = {
    dev: {
        webUrl: "http://localhost:3000",
        apiUrl: "http://localhost:5555/v1",
        cdnUrl: "https://bits-modules-dev.aws16.tv2.no/module_sets"
    },
    stage: {
        webUrl: "https://bits-web.stage-alpha.tv2.no",
        apiUrl: "https://bits-api.stage-alpha.tv2.no/v1",
        cdnUrl: "https://bits-modules-stage.aws16.tv2.no/module_sets"
    },
    prod: {
        webUrl: "https://bits-web.alpha.tv2.no",
        apiUrl: "https://bits-api.alpha.tv2.no/v1",
        cdnUrl: "https://bits-modules-prod.aws16.tv2.no/module_sets"
    }
};

export default config;
